<template>
    <div class="topic-tree">
        <div class="label-wrapper text-truncate row">
            <div :style="indent" :class="labelClasses" class="col-7">
                <span class="label-caret" @click="toggleChildren">
                    <b-icon-caret-down-fill v-if="topics && showChildren"/>
                    <b-icon-caret-right-fill v-if="topics && !showChildren"/>
                </span>
                <span class="label-index">{{ composeOrder(topic.order) }}</span>
                <span class="label-title" :class="titleClass" v-b-toggle="'collapse-'+topic.id">
                    {{ label }}
                </span>
                <span v-if="duration" class="small duration">({{ duration | formatDuration }})</span>

                <b-collapse :id="'collapse-'+topic.id" :visible="print" class="col-12" v-if="topicEdulabLogs.length>0">
                    <br/>
                    <div class="activity-history"
                         v-for="activity in topicEdulabLogs"
                         :key="activity.id">
                        <span style="opacity: .7">
                            <b>{{ activity.edulabLog.edulabActivityName }}</b> ({{ activity.edulabLog.type }})
                        </span>
                        <br/>
                        <span :class="activity.status">
                                <b-icon-check-circle v-if="activity.status == 'Completed'"/>
                                <b-icon-exclamation-circle-fill
                                    v-if="activity.status == 'CompletedWithErrors' || topic.status == 'NotCompletedWithErrors'"/>
                                {{ radioOptions.find(item => item.value == activity.status).text }}
                        </span>
                        <br>
                        <span style="opacity: .7"><b-icon icon="calendar3"/> {{ activity.registeredAt | formatDateTime }} ({{ getActivityDuration(activity.edulabLog) | formatDuration }})</span>

                    </div>
                </b-collapse>

            </div>
            <div class="col-4">
                <span v-if="topic.activityLog" class="teacher-note text-muted">
                    {{ topic.activityLog.teacherNote }}
                </span>
            </div>
            <div class="col-1 text-right">
                <span class="label-status" :class="titleClass" v-if="program.edulab == 0">
                    <span v-if="isDone">
                        <b-icon-check-square v-if="topic.activityLog.presenceType == 'real'"/>
                        <b-icon-square v-else />
                        &nbsp;
                        <b-icon-check-square v-if="topic.activityLog.presenceType == 'remote'"/>
                        <b-icon-square />
                    </span>
                    <span v-else>
                        <b-icon-square />
                        &nbsp;
                        <b-icon-square />
                    </span>
                </span>
                <span class="label-status" :class="titleClass" v-else>
                    <span v-if="isDone">
                        <b-icon-check-square v-if="topic.activityLog.status == 'Completed'"/>
                    <b-icon-exclamation-square
                        v-if="topic.activityLog.status == 'CompletedWithErrors' || topic.activityLog.status == 'NotCompletedWithErrors'"/>
                        <b-icon-dash-square v-if="!topic.activityLog.status"/>
                    </span>
                    <span v-else>
                        <b-icon-square />
                    </span>
                </span>
            </div>

        </div>
        <div :class="(!showChildren) ? 'hidden' : ''">
            <topic-tree-view
                v-for="topic in topics"
                :topic="topic"
                :key="topic.id"
                :topics="topic.children"
                :label="topic.title"
                :depth="depth + 1"
                :parentOrder="topic.order"
                :activityLogs="activityLogs"
                :program="program"
                :print="print"
            />
        </div>

    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: "topic-tree-view",
    props: [ 'topics', 'label','topic', 'depth', 'parentOrder', 'activityLogs', 'print', 'program' ],
    data() {
        return {
            topicsActivityLog: [],
            topicEdulabLogs: [],
            showChildren: true,
            x: null,
            duration: 0,
            radioOptions: [
                {text: 'Completato', value: 'Completed'},
                {text: 'Completato con errori', value: 'CompletedWithErrors'},
                {text: 'Non completato con errori', value: 'NotCompletedWithErrors'},
                {text: 'Da completare', value: 'NotCompleted'},
            ],
        }
    },
    computed: {
        iconClasses() {
            return {
                'chevron-right': !this.showChildren,
                'chevron-down': this.showChildren
            }
        },
        isDone() {
            return this.getActivityLog()
        },
        labelClasses() {
            return { 'has-children': this.topics }
        },
        titleClass() {
            let status = this.getActivityLog();
            return {
                'text-muted': !status,
                'text-success': (status == 'Completed'),
                'text-warning': (status == 'NotCompleted'),
                'text-danger': (status == 'NotCompletedWithErrors' || status == 'CompletedWithErrors'),
            }
        },
        indent() {
            return { transform: `translate(${this.depth * 25}px)` }
        }
    },
    methods: {
        toggleChildren() {
            this.showChildren = !this.showChildren;
        },
        setActiveTopic(topic) {
            this.$parent.setActiveTopic(topic);
        },
        composeOrder(topicOrder) {
            let order = '';
            if (this.parentOrder) {
                order += this.parentOrder+'.';
            }
            order += topicOrder
            return order
        },
        getActivityLog() {
            let self = this
            function findTopicDone(topicDone) {
                return (topicDone.topicId === self.topic.id && topicDone.programId === self.topic.programId);
            }
            self.topic.activityLog = this.activityLogs.find(findTopicDone, self);
            if (self.topic.activityLog) return self.topic.activityLog.status;
            else return false;
        },
        setTopicStatus() {
            const topicDoneArray = this.activityLogs.filter(element => element.topicId == this.topic.id) || null;
            if (topicDoneArray) {
                this.topicsActivityLog = topicDoneArray;
                //enum('NotCompleted', 'NotCompletedWithErrors', 'CompletedWithErrors', 'Completed')
                let status = {
                    Completed: 0,
                    CompletedWithErrors: 0,
                    NotCompletedWithErrors: 0,
                    NotCompleted: 0,
                };
                // check ultimate status inside all activities
                for (const activity of topicDoneArray) {
                    if (activity.status == 'Completed') {
                        status.Completed++;
                    } else if (activity.status == 'CompletedWithErrors') {
                        status.CompletedWithErrors++;
                    } else if (activity.status == 'NotCompletedWithErrors') {
                        status.NotCompletedWithErrors++;
                    } else if (activity.status == 'NotCompleted') {
                        status.NotCompleted++;
                    }
                }
                if (status.Completed > 0) this.topic.status = 'Completed';
                else if (status.CompletedWithErrors > 0) this.topic.status = 'CompletedWithErrors';
                else if (status.NotCompletedWithErrors > 0) this.topic.status = 'NotCompletedWithErrors';
                else if (status.NotCompleted > 0) this.topic.status = 'NotCompleted';
                else this.topic.status = null;
            }
        },
        getActivityDuration(activity) {
            const start = moment(activity.dateStart);
            const end = moment(activity.dateEnd);
            const diff = end.diff(start);
            return diff;
        },
        setTopicDuration(a) {
            let duration = 0;
            for (const activity of this.topicEdulabLogs) {
                duration += this.getActivityDuration(activity.edulabLog);
            }
            if (duration > 0) {
                this.duration = duration
                this.$parent.duration += duration
            }
        }
    },
    filters: {
        formatDateTime: function (date) {
            return moment(date).format('DD/MM/YYYY - HH:mm');
        },
        formatDuration(diff) {
            const duration = moment.duration(diff);
            const hours = (duration.hours() > 0) ? duration.hours(): '00';
            const minutes = (duration.minutes() > 0) ? duration.minutes(): '00';
            const seconds = (duration.seconds() > 0) ? duration.seconds(): '00';
            //let result = hours + ':' + minutes + ':' + seconds + 's';
            let result = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0') + 's';
            return result;
        }
    },
    mounted() {
        this.topic.newActivityLog = {
            status: null,
            teacherNote: null
        };
        this.topicEdulabLogs = this.activityLogs.filter(element => element.topicId == this.topic.id && element.edulabLog !== null) || [];
        this.setTopicDuration();
        //this.setTopicStatus();
    }
}
</script>

<style scoped>
.topic-tree .label-wrapper {

    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #dee2e6;
}
.topic-tree .has-children {
    cursor: pointer;
}

.topic-tree .label-index {
    font-weight: bold;
    padding-right: 10px;
}
.topic-tree .label-caret {
    display: inline-block;
    width: 25px;
}
.topic-tree . teacher-note {
}

.Completed {
    color: #28a745;
}

.NotCompletedWithErrors, .CompletedWithErrors {
    color: #dc3545
}

.NotCompleted {
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
    display: none;
}

.activity-history {
    margin-left: 30px;
    border-top: 1px solid #eee;
    padding: 10px 0px;
    font-size: .90rem;
    border-left: 3px solid black;
    padding-left: 10px
}

.duration {
    display: block;
    margin-left: 60px;
}
</style>



